module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DeveloPassion","short_name":"DeveloPassion","start_url":"/","background_color":"#242942","theme_color":"#2a2f4a","display":"minimal-ui","icon":"src/assets/images/developassion-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"93f722be5d704e6e8eb32a0f55038959"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"developassion.be"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
